import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import Gallery from "../../../components/Gallery/gallery"

const SportAndOutdoor = () => (
  <Layout>
    <SEO title="Sport and Outdoors" />
    <HeroInner title="Sport and Outdoors" imageAlt="Sport and Outdoors" imagePath="services/retail-products/sports-and-outdoors/hero.jpg" />
    <ArticleFullWidth
      descriptions={[
        `Your one-stop shop for all your outdoor, sports, and fitness needs! Choose from a wide range of items from sporting goods to fitness supplies, camping equipment, and hunting and fishing gear. Plus, we offer a large selection of outdoor apparel all at low, wholesale prices.`,
      ]}
    />

    <Gallery
      items={[
        {
          name: "Airsoft",
          imagePath: "services/retail-products/sports-and-outdoors/airsoft.jpg",
        },
        {
          name: "Climbing",
          imagePath: "services/retail-products/sports-and-outdoors/climbing.jpg",
        },
        {
          name: "Cycling",
          imagePath: "services/retail-products/sports-and-outdoors/cycling.jpg",
        },
        {
          name: "Outdoor",
          imagePath: "services/retail-products/sports-and-outdoors/outdoor.jpg",
        },
        {
          name: "Fishing",
          imagePath: "services/retail-products/sports-and-outdoors/fishing.jpg",
        },
        {
          name: "Snowboard, Skateboard & Skate",
          imagePath: "services/retail-products/sports-and-outdoors/snowboard.jpg",
        },
      ]}
    />
  </Layout>
)

export default SportAndOutdoor
